import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption } from 'models/device.enums';

import { Box, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import apiSlice from '@services/api';
import { ConnectorTypeIcon } from '@views/Chargers/ConnectorTypeIcon';
import { useGetChargerMenuItems } from '@views/Chargers/useGetChargerMenuItems';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { Menu } from '@views/reporting/table/Menu';

import { DevicesAndGroupsContext } from '../DevicesAndGroupsProvider';
import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { ChargerControllerGroup } from '../siteDevicesAndGroupsHelpers/ChargerControllerGroup';
import { ConnectorActiveProgramContent } from '../siteDevicesAndGroupsHelpers/ConnectorActiveChargingProgramContent';
import { ConnectorStatusContent } from '../siteDevicesAndGroupsHelpers/ConnectorStatusContent';
import { DeviceIcon } from '../siteDevicesAndGroupsHelpers/DeviceIcon';
import { DeviceSiteContent } from '../siteDevicesAndGroupsHelpers/DeviceSiteContent';
import { DeviceVendorModel } from '../siteDevicesAndGroupsHelpers/DeviceVendorModel';
import { LmcChargerCommunicationStatus } from '../siteDevicesAndGroupsHelpers/LmcChargerCommunicationStatus';
import { UserFavoriteDeviceStar } from '../siteDevicesAndGroupsHelpers/UserFavoriteDeviceStar';
import { getLinkToDevice, isVoolDevice } from '../siteDevicesAndGroupsHelpers/siteDevicesUtils';
import { SiteChargersTableRowSkeleton, SiteChargersTableSkeleton } from './SiteChargersTableSkeleton';

type SiteChargersColumnData = ColumnData<ChargerWithStats>;

const useColumns = (
  lmcMultiPointsLength: number,
  chargers: Device[],
  hiddenColumns?: (keyof ChargerWithStats | 'menu')[],
): SiteChargersColumnData[] => {
  const { t } = useTranslation();

  const isProgramActiveOnAnyCharger = chargers.some((charger) => charger.activePrograms?.length);

  const columns: SiteChargersColumnData[] = [
    {
      width: 180,
      label: t('name', 'Name'),
      dataKey: 'displayName',
      sortable: true,
    },
    {
      width: 180,
      label: t('site', 'Site'),
      dataKey: 'siteUuid',
      sortable: true,
    },
    {
      width: 160,
      label: t('firmware', 'Firmware'),
      dataKey: 'firmwareVersion',
      sortable: true,
    },
    {
      width: 160,
      label: t('connector', 'Connector'),
      dataKey: 'connectors',
      sortable: true,
    },
    {
      width: 160,
      label: t('status', 'Status'),
      dataKey: 'computedStatus',
      sortable: true,
    },
    ...((isProgramActiveOnAnyCharger
      ? [
          {
            width: 170,
            label: t('activeProgram', 'Active program'),
            dataKey: 'activePrograms',
            sortable: true,
          },
        ]
      : []) as SiteChargersColumnData[]),
    ...((lmcMultiPointsLength
      ? [
          {
            width: 170,
            label: t('controller/group', 'Controller/Group'),
            dataKey: 'lmcMultipointUuid',
            sortable: true,
          },
        ]
      : []) as SiteChargersColumnData[]),
    {
      width: 170,
      label: t('lmcConnection', 'LMC connection'),
      dataKey: 'communicatesViaLmc',
      sortable: true,
    },
    {
      width: 60,
      label: t('sessions', 'Sessions'),
      dataKey: 'numSessions',
      sortable: true,
      numeric: true,
    },
    {
      width: 180,
      label: t('consumption', 'Consumption') + ' kWh',
      dataKey: 'consumption',
      sortable: true,
      numeric: true,
    },
    {
      width: 20,
      label: '',
      dataKey: 'menu',
      sortable: false,
    },
  ];
  return hiddenColumns ? columns.filter((column) => !hiddenColumns.includes(column.dataKey)) : columns;
};

type RenderRowsProps = {
  rows: ChargerWithStats[];
  columns: SiteChargersColumnData[];
  lmcMultipoints: LmcMultipointWithStats[];
  selectedDevice?: ChargerWithStats;
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
  onDeviceSelected: Dispatch<SetStateAction<Device | undefined>>;
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: ChargerWithStats;
};

const TableRow = ({
  row,
  columns,
  lmcMultipoints,
  selectedDevice,
  onModalOptionSelected,
  onDeviceSelected,
}: TableRowProps) => {
  const getChargerMenuItems = useGetChargerMenuItems({
    onModalOptionSelected,
    onDeviceSelected,
  });

  return (
    <StyledTableRow
      key={row.uuid}
      sx={{
        bgcolor: row.uuid === selectedDevice?.uuid ? '#F4F4F4' : 'background.paper',
      }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'displayName':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link to={getLinkToDevice(row)} className="flex w-full text-left lg:min-w-[200px]">
                  <DeviceIcon device={row} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    pl={{
                      lg: 2,
                    }}
                  >
                    <Typography variant="p16b">{row.displayName}</Typography>
                    <Typography variant="p14">
                      <DeviceVendorModel device={row} />
                    </Typography>
                  </Box>
                </Link>
              </TableCell>
            );
          case 'siteUuid':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <DeviceSiteContent device={row} />
              </TableCell>
            );
          case 'connectors':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  {row.connectors?.map((connector) => (
                    <ConnectorTypeIcon
                      key={connector.connectorId || row.uuid}
                      className="flex-row items-center"
                      connectorId={connector.isTheOnlyConnector ? undefined : connector.connectorId}
                      connectorType={connector.connectorType}
                    />
                  ))}
                </Box>
              </TableCell>
            );
          case 'computedStatus':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  {row.connectors?.map((connector) => (
                    <ConnectorStatusContent key={connector.connectorId || row.uuid} connector={connector} />
                  ))}
                </Box>
              </TableCell>
            );
          case 'activePrograms': {
            const activePrograms = getUnifiedConnectors(row).map((connector) => connector.activeProgram);
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  {activePrograms?.map((activeProgram, index) => {
                    return activeProgram ? (
                      <ConnectorActiveProgramContent key={index} activeProgram={activeProgram} />
                    ) : (
                      <Typography key={index} variant="p14">
                        -
                      </Typography>
                    );
                  })}
                </Box>
              </TableCell>
            );
          }
          case 'lmcMultipointUuid': {
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {isVoolDevice(row) && <ChargerControllerGroup charger={row} lmcMultipoints={lmcMultipoints} />}
              </TableCell>
            );
          }
          case 'communicatesViaLmc':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <LmcChargerCommunicationStatus charger={row} lmcMultipoints={lmcMultipoints} type="text" />
              </TableCell>
            );
          case 'numSessions':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.numSessions || '-'}
              </TableCell>
            );
          case 'consumption':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.consumption ? row.consumption.toFixed(2) : '-'}
              </TableCell>
            );
          case 'menu':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Box display="flex" alignItems="center" justifyContent="end" gap={0.5}>
                  {isSubdomainPartner() && <UserFavoriteDeviceStar device={row} />}
                  <Menu menuOptions={getChargerMenuItems(row)} />
                </Box>
              </TableCell>
            );
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey] ?? '-')}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

type SiteChargersMaterialTableProps = {
  selectedDevice?: ChargerWithStats;
  onDeviceSelected: Dispatch<SetStateAction<Device | undefined>>;
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
};

export const SiteChargersMaterialTable = ({
  selectedDevice,
  onDeviceSelected,
  onModalOptionSelected,
}: SiteChargersMaterialTableProps) => {
  const { siteUuid, lmcMultipointsWithStats, chargersWithStats, dataLoading, order, orderBy } =
    useContext(DevicesAndGroupsContext);

  const hiddenColumns: (keyof ChargerWithStats | 'menu')[] = siteUuid
    ? ['siteUuid', 'firmwareVersion', 'communicatesViaLmc']
    : ['connectors', 'activePrograms', 'lmcMultipointUuid', 'numSessions', 'consumption'];

  const columns = useColumns(lmcMultipointsWithStats.length, chargersWithStats, hiddenColumns);

  apiSlice.useVehiclesQuery({});

  return (
    <MaterialTable
      sortingStorageKey="siteChargersTable"
      data={chargersWithStats}
      isLoading={dataLoading}
      headerProps={{
        columns,
        order,
        orderBy,
      }}
      renderRows={(rows, columns) =>
        renderRows({
          rows,
          columns,
          lmcMultipoints: lmcMultipointsWithStats,
          selectedDevice,
          onModalOptionSelected,
          onDeviceSelected,
        })
      }
      loaders={{
        TableLoader: SiteChargersTableSkeleton,
        RowLoader: SiteChargersTableRowSkeleton,
      }}
    />
  );
};
