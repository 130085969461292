import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { ConnectorComputedStatus, DeviceModalOption } from 'models/device.enums';

import { Modal } from '@components/modals/Modal';
import warningIcon from '@images/icons/Warning.svg';
import { getErrorMessage } from '@services/api/api-errors';
import { useResetDeviceMutation, useUpdateDeviceFirmwareMutation } from '@services/devices/endpoints';

import { getUnifiedConnectors } from './connectorsUtil';

type DeviceOcppFirmwareModalProps = {
  device: Device;
  isOpen: boolean;
  option?: DeviceModalOption;
  closeModal: () => void;
  onSuccess?: (option: DeviceModalOption) => void;
};

export const DeviceOcppFirmwareModal = ({
  device,
  isOpen,
  option,
  closeModal,
  onSuccess,
}: DeviceOcppFirmwareModalProps) => {
  const { t } = useTranslation();
  const deviceNameLabel = device.name || device.serialNumber;
  const connectors = getUnifiedConnectors(device);
  const isDeviceCharging = connectors.some((connector) =>
    [ConnectorComputedStatus.CHARGING, ConnectorComputedStatus.STARTING].includes(connector.computedStatus),
  );

  const isToRestart = option === DeviceModalOption.OCPP_RESET;
  const actionLabel = isToRestart ? t('restart', 'Restart') : t('update', 'Update');

  const [errorMessage, setErrorMessage] = useState('');

  const [resetDevice, { isLoading: isResetLoading }] = useResetDeviceMutation();
  const [updateDeviceFirmware, { isLoading: isUpdateLoading }] = useUpdateDeviceFirmwareMutation();

  const handleClose = () => {
    closeModal();
    setErrorMessage('');
  };

  const handleSave = async () => {
    try {
      if (isToRestart) {
        await resetDevice(device.uuid).unwrap();
      } else {
        await updateDeviceFirmware(device.uuid).unwrap();
      }
      onSuccess?.(option!);
      handleClose();
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
    }
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      confirmText={`${t('yes', 'Yes')}, ${actionLabel.toLowerCase()}`}
      title={`${actionLabel}  ${t('device', 'Device').toLowerCase()}?`}
      error={errorMessage}
      isLoading={isResetLoading || isUpdateLoading}
      confirmButtonColor={isToRestart && isDeviceCharging ? 'primary' : 'success'}
      onConfirm={handleSave}
    >
      <div className="flex flex-col gap-6 px-6 font-poppins sm:px-0">
        <p>
          {t('ocppFirmwareModalText', 'Are you sure you want to {{action}} the device', {
            action: actionLabel.toLowerCase(),
          })}{' '}
          <span className="font-semibold">{deviceNameLabel}</span>?
        </p>
        {isToRestart && isDeviceCharging && (
          <div className="flex items-center justify-start gap-2 py-2 text-vermillion sm:items-start">
            <img src={warningIcon} alt="warning" />
            <p>
              {t(
                'ocppFirmwareModalSubText',
                'This device has an active charging session. Restarting now will end this session.',
              )}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};
