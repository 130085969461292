import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption, DeviceOcppActionOption } from 'models/device.enums';
import { Site } from 'models/site';

import { DeleteDeviceModal } from '@components/devices/DeleteDeviceModal';
import { ActionButton, TabsWithMenu } from '@components/tabs/TabsWithMenu';
import { DeviceOcppFirmwareModal } from '@views/Devices/DeviceOcppFirmwareModal';
import { DeviceOcppMessage } from '@views/Devices/DeviceOcppMessage';
import DeviceOcppVariablesModal from '@views/Devices/OcppVariablesModal';
import { UsageLocation } from '@views/Devices/UsageLocation';

import { useGetLmcMultipointMenuItems } from './useGetLmcMultipointMenuItems';

type SingleLmcProps = {
  lmc: Device;
  site: Site;
  openEditModal: () => void;
};

export const SingleLmc = ({ lmc, site, openEditModal }: SingleLmcProps) => {
  const { t } = useTranslation();

  const [modalOption, setModalOption] = useState<DeviceModalOption>();
  const [deviceOcppAction, setDeviceOcppAction] = useState<DeviceOcppActionOption>();

  const tabs = [
    {
      label: t('overview', 'Overview'),
      path: 'overview',
    },
  ];
  const [activeTab] = tabs;
  const globalActionButton: ActionButton = {
    label: t('configuration', 'Configuration'),
    path: `${activeTab.path}/details`,
  };

  const getMenuItems = useGetLmcMultipointMenuItems({
    onModalOptionSelected: setModalOption,
    openEditModal,
    usageLocation: UsageLocation.SINGLE_DEVICE,
  });
  const lmcMenuItems = getMenuItems(lmc);

  return (
    <>
      <TabsWithMenu
        activeTab={activeTab}
        tabs={tabs}
        menuItems={lmcMenuItems}
        globalActionButton={globalActionButton}
      />
      <DeviceOcppMessage
        device={lmc}
        deviceOcppAction={deviceOcppAction}
        onClose={() => setDeviceOcppAction(undefined)}
        onUpdateAgain={() => setModalOption(DeviceModalOption.OCPP_UPDATE)}
      />
      <Outlet
        context={{
          device: lmc,
          site,
        }}
      />
      <DeleteDeviceModal
        isOpen={modalOption === DeviceModalOption.DELETE}
        closeModal={() => setModalOption(undefined)}
        device={lmc}
      />
      <DeviceOcppVariablesModal
        isOpen={modalOption === DeviceModalOption.OCPP_VARIABLES}
        closeModal={() => {
          setModalOption(undefined);
        }}
        device={lmc}
      />
      <DeviceOcppFirmwareModal
        isOpen={modalOption === DeviceModalOption.OCPP_UPDATE}
        option={modalOption}
        closeModal={() => setModalOption(undefined)}
        onSuccess={() => {
          setDeviceOcppAction(DeviceOcppActionOption.OCPP_UPDATE);
        }}
        device={lmc}
      />
    </>
  );
};
