import { LmcStatusDefinitionItem } from './useGetLmcComputedStatusDef';

type LmcStatusPillProps = {
  statusDef: LmcStatusDefinitionItem;
};

export const LmcStatusPill = ({ statusDef }: LmcStatusPillProps) => {
  return (
    <div
      className={`
        w-fit
        ${statusDef.pillColorClassName}
        flex
        items-center
        whitespace-nowrap
        rounded
        px-2
        leading-6
        text-white
      `}
    >
      {statusDef.statusLabel}
    </div>
  );
};
