import { useTranslation } from 'react-i18next';

import { sum } from 'lodash';
import { Device } from 'models/device';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { LmcStatusPill } from './LmcStatusPill';
import { useGetLmcComputedStatusDef } from './useGetLmcComputedStatusDef';

type LmcStatusProps = {
  lmc: Device;
  smaller?: boolean;
};

export const LmcStatus = ({ lmc, smaller }: LmcStatusProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const connectors = getUnifiedConnectors(lmc);
  const currents: number[] = connectors.flatMap((connector) =>
    Object.entries(connector)
      .filter(([key]) => key.startsWith('current_'))
      .map(([, value]) => value),
  );

  const currentLoadPercentage = (sum(currents) / ((lmc?.gridConnection || 32) * currents.length)) * 100 || 0;

  const getLmcComputedStatusDef = useGetLmcComputedStatusDef();
  const statusDef = getLmcComputedStatusDef(lmc);

  const StatusIcon = statusDef.statusIcon;

  if (smaller) {
    return (
      <Box
        width={1}
        borderRadius={4}
        sx={{
          display: 'flex',
          py: 2,
          pl: 2,
          pr: 1,
          gap: 1,
          ...statusDef.backgroundStyle,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          width={40}
          height={40}
          border={2}
          sx={statusDef.backgroundStyle}
        >
          <StatusIcon width={20} height={20} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="p14b" color={theme.palette.raisingBlack}>
            {statusDef.statusLabel}
          </Typography>
          <Typography variant="p12" color={theme.palette.darkGray}>
            {currentLoadPercentage.toFixed()}% {t('ofTotalConnection', 'Of total connection')}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      width={1}
      borderRadius={4}
      sx={{
        minWidth: !isMobile ? '248px' : '100%',
        bgcolor: statusDef.backgroundStyle.bgcolor,
        p: 2,
        width: !isMobile ? '1/3' : 'full',
      }}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h3" color={theme.palette.raisingBlack}>
        {currentLoadPercentage.toFixed()}%
      </Typography>
      <Typography variant="p14" color={theme.palette.darkGray}>
        {t('ofTotalConnection', 'Of total connection')}
      </Typography>
      <Box flex="1 1 0" />
      <LmcStatusPill statusDef={statusDef} />
    </Box>
  );
};
