import { Device } from 'models/device';

import { useGetLmcComputedStatusDef } from '@views/LMC/useGetLmcComputedStatusDef';

export const LmcStatusContent = ({ lmc }: { lmc: Device }) => {
  const getLmcComputedStatusDef = useGetLmcComputedStatusDef();
  const statusDef = getLmcComputedStatusDef(lmc);

  return (
    <div className="flex items-center">
      <img
        src={statusDef.icon}
        alt={statusDef.statusLabel}
        className={`mr-2 inline-block rounded-full ${statusDef.iconClassName}`}
      />
      <span className={statusDef.textClassName}>{statusDef.statusLabel}</span>
    </div>
  );
};
