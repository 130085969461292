import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';
import moment from 'moment';

import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material';

import { ReactComponent as UnknownIcon } from '@images/icons/DevicePlugType/Unknown.svg';
import availableIcon from '@images/icons/DeviceStatus/Status_Available.svg';
import faultIcon from '@images/icons/DeviceStatus/Status_Fault.svg';
import offlineIcon from '@images/icons/DeviceStatus/Status_Offline.svg';
import statusUnknownIcon from '@images/icons/DeviceStatus/Status_Unknown.svg';
import updatingIcon from '@images/icons/DeviceStatus/Status_Updating.svg';
import { ReactComponent as SpinnerIcon } from '@images/icons/Spinner_2.svg';
import { ReactComponent as CheckIcon } from '@images/icons/subscriptions/check.svg';

export type LmcStatusDefinitionItem = {
  icon: string;
  iconClassName: string;
  pillColorClassName: string;
  statusLabel: string;
  textClassName: string;
  statusIcon: FunctionComponent<{ width?: number | string; height?: number | string }>;
  backgroundStyle: {
    bgcolor: string;
    borderColor: string;
    color: string;
  };
};

export const useGetLmcComputedStatusDef = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getLmcComputedStatusDef = (lmc: Device): LmcStatusDefinitionItem => {
    const isUpdating =
      !!lmc.firmwareUpdateStatus &&
      ['Downloading', 'Installing', 'InstallRebooting'].includes(lmc.firmwareUpdateStatus);

    if (
      isUpdating &&
      (lmc.status !== DeviceStatus.OFFLINE || moment().diff(moment.utc(lmc.firmwareUpdateStartTime), 'minute') < 30)
    ) {
      return {
        icon: updatingIcon,
        iconClassName: 'bg-mustard-yellow',
        pillColorClassName: 'bg-mustard-yellow',
        statusLabel:
          lmc.status === DeviceStatus.ONLINE ? t('updating', 'Updating') : t('updatingOffline', 'Updating (offline)'),
        textClassName: 'text-mustard-yellow',
        statusIcon: SpinnerIcon,
        backgroundStyle: {
          bgcolor: theme.palette.lightMustardYellow,
          borderColor: theme.palette.mustardYellow,
          color: theme.palette.mustardYellow,
        },
      };
    }

    const statusToDef: Record<DeviceStatus, LmcStatusDefinitionItem> = {
      [DeviceStatus.ONLINE]: {
        icon: availableIcon,
        iconClassName: 'bg-forest',
        pillColorClassName: 'bg-forest',
        statusLabel: t('online', 'Online'),
        textClassName: 'text-forest',
        statusIcon: CheckIcon, // FIXME: use spinner icon
        backgroundStyle: {
          bgcolor: theme.palette.lightGreen,
          borderColor: theme.palette.forestGreenCrayola,
          color: theme.palette.forestGreenCrayola,
        },
      },
      [DeviceStatus.OFFLINE]: {
        icon: offlineIcon,
        iconClassName: 'bg-gray-600',
        pillColorClassName: 'bg-gray-600',
        statusLabel: t('offline', 'Offline'),
        textClassName: 'text-gray-600',
        statusIcon: Close,
        backgroundStyle: {
          bgcolor: theme.palette.lightGray,
          borderColor: theme.palette.darkGray,
          color: theme.palette.darkGray,
        },
      },
      [DeviceStatus.FAULTED]: {
        icon: faultIcon,
        iconClassName: 'bg-black',
        pillColorClassName: 'bg-black',
        statusLabel: t('faulted', 'Faulted'),
        textClassName: 'text-black',
        statusIcon: UnknownIcon,
        backgroundStyle: {
          bgcolor: theme.palette.lightGray,
          borderColor: theme.palette.raisingBlack,
          color: theme.palette.raisingBlack,
        },
      },
    };

    const unknownStatusDef: LmcStatusDefinitionItem = {
      icon: statusUnknownIcon,
      iconClassName: 'bg-gray-600',
      pillColorClassName: 'bg-gray-600',
      statusLabel: t('unknown', 'Unknown'),
      textClassName: 'text-gray-600',
      statusIcon: UnknownIcon,
      backgroundStyle: {
        bgcolor: theme.palette.lightGray,
        borderColor: theme.palette.darkGray,
        color: theme.palette.darkGray,
      },
    };

    return statusToDef[lmc.status] ?? unknownStatusDef;
  };

  return getLmcComputedStatusDef;
};
